
import { Component, Vue } from 'vue-property-decorator';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import StatisticsAddNew from '@/views/StatisticsAddNew.vue';
import ReportRepository from '@/repositories/ReportRepository';
import { StatisticsCmsColumns } from '@/interfaces/components/cms/StatisticsCmsColumns';
import Report from '@/models/Report';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import moment from 'moment';

@Component({
    name: 'StatisticsModuleCms',
    components: {
        StatisticsAddNew,
        SettingsTopBar,
    },
})
export default class StatisticsModuleCms extends Vue {
    private isStatisticsModalVisible: boolean = false;
    private selectedRowKeys: string[] = [];
    private searchQuery: string = '';
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedReportId: string = '';

    private get columns() {
        return [
            { title: this.$t('Title'), dataIndex: 'name', key: 'name' },
            { title: this.$t('Creator'), dataIndex: 'creator', key: 'creator' },
            { title: this.$t('Date created'), dataIndex: 'createdAt', key: 'createdAt' },
        ];
    }

    private get filteredReports() {
        if (this.searchQuery) {
            return this.reports.filter((report) => {
                return report.name.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        }

        return null;
    }

    private get reports(): StatisticsCmsColumns[] {
        let reports: any = ReportRepository.getAll();

        if (reports?.length) {
            reports = reports.map((report: StatisticsCmsColumns) => {
                const formattedDate = moment(report.createdAt).format('DD.MM.YYYY');
                return {
                    key: report.id,
                    name: report.name,
                    creator: 'Admin',
                    createdAt: formattedDate,
                };
            });
        }

        return reports;
    }

    public get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get userRoles() {
        return this.$store.getters['jwtData/userRoles'];
    }

    private get isSuperAdmin() {
        return this.userRoles.includes('ROLE_SUPER_ADMIN');
    }

    private addNew() {
        if (!this.isSuperAdmin) {
            this.$notification.error({
                message: this.$t('Error creating report') as string,
                description: this.$t('You do not have the rights to create reports') as string,
            });

            return;
        }

        this.isStatisticsModalVisible = true;
    }

    // Delete report if user is super admin
    private confirmDelete() {
        if (!this.isSuperAdmin) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: this.$t('You do not have the rights to delete reports') as string,
            });

            return;
        }

        if (this.selectedRowKeys?.length === 0) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: this.$t('Please select at least one report to delete') as string,
            });

            return;
        }

        this.$confirm({
            title: 'Are you sure you want to delete selected reports?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.deleteReport();
            },
        });
    }

    private async deleteReport() {
        this.loadingOverlay.start();

        if (!this.selectedRowKeys.length) {
            return;
        }

        // Delete reports locally and on server
        try {
            for (const reportId of this.selectedRowKeys) {
                await Report.deleteExisting(reportId);
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: typeof e === 'string' ? e : 'Unknown error',
            });
        } finally {
            this.loadingOverlay.stop();
        }

        this.selectedRowKeys = [];
        this.$notification.success({
            message: this.$t('Report successfully deleted') as string,
            description: this.$t('') as string,
        });

        return Promise.resolve();
    }

    // Fetch and format reports for table
    private async fetchAllReports() {
        this.loadingOverlay.start();

        try {
            await Report.getAll();
        } catch (e) {
            Promise.reject(e);
        } finally {
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    private openReport(reportId: string) {
        this.isStatisticsModalVisible = true;
        this.selectedReportId = reportId;
    }

    private createReport() {
        EventBus.$emit(EventBusEvents.createStatisticsReport);
    }

    private onSelectChange(selectedRowKeys: string[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    private closePopup() {
        this.isStatisticsModalVisible = false;
    }

    private resetReportId() {
        // Reset report id when modal is closed to prevent opening the same report again
        this.selectedReportId = '';
    }

    private async mounted() {
        await this.fetchAllReports();
    }
}
