var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SettingsTopBar',{attrs:{"pathCurrent":"CMS","pathBefore":"Settings /","showBackButton":true,"showSubmitButton":false}}),_c('div',{staticClass:"statistics-cms-wrapper"},[_c('div',{staticClass:"statistics__top-bar"},[_c('span',{staticClass:"statistics__title"},[_vm._v(_vm._s(_vm.$t('Reports')))]),_c('div',{staticClass:"statistics__button-wrapper"},[_c('a-button',{staticClass:"statistics__create-button",attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Create')))]),_c('a-button',{staticClass:"statistics__delete-button",attrs:{"type":"danger"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.$t('Delete')))])],1)]),_c('div',{staticClass:"statistics-cms-content"},[_c('div',{staticClass:"search-bar"},[_c('a-input-search',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Search chart data..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"statistics-table-wrapper"},[_c('a-table',{staticClass:"statistics-table",attrs:{"columns":_vm.columns,"data-source":_vm.filteredReports || _vm.reports,"pagination":{ pageSize: 8 },"row-selection":{
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectChange,
                    },"rowClassName":() => 'reports__table-row',"rowKey":(record) => record.key,"customRow":(record) => {
                            return {
                                on: {
                                    click: () => _vm.openReport(record.key), // click row
                                },
                            };
                        }}})],1)]),_c('a-modal',{attrs:{"ok-text":_vm.$t(_vm.selectedReportId ? 'Update' : 'Add'),"cancel-text":_vm.$t('Cancel'),"destroyOnClose":"","wrapClassName":"statistics-modal","afterClose":_vm.resetReportId,"centered":"","width":"80vw"},on:{"ok":_vm.createReport,"cancel":_vm.closePopup},model:{value:(_vm.isStatisticsModalVisible),callback:function ($$v) {_vm.isStatisticsModalVisible=$$v},expression:"isStatisticsModalVisible"}},[_c('statistics-add-new',{attrs:{"reportId":_vm.selectedReportId,"isAdmin":_vm.isSuperAdmin},on:{"closePopup":_vm.closePopup}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }